<template>
  <v-container>
    <v-row>
      <v-col md="4" cols="12">
        <v-card elevation="10">
          <v-card-title>
            <h5 class="text-center">MENU ADMINISTRACION</h5>
          </v-card-title>
          <v-card-text>
            <v-btn
              block
              color="primary"
              class="my-3"
              @click="$router.push({ name: 'Herramientas' })"
              >Equipos/Herramientas</v-btn
            >
         
            <v-btn
              block
              color="primary"
              class="my-3"
              @click="$router.push('marcas')"
              >Marcas de equipos</v-btn
            >

            <v-btn
              block
              color="primary"
              class="my-3"
              @click="$router.push({ name: 'Modelos' })"
              >Modelos de equipos</v-btn
            >
            
            <v-btn 
              block 
              color="primary" 
              class="my-3"
              @click="$router.push({ name: 'Bodegas' })"
            >Bodegas</v-btn>
           
            <v-btn 
              block
              color="primary" 
              class="my-3"
              @click="$router.push({ name: 'Usuarios' })"
            >Usuarios</v-btn>
            <v-btn 
              block
              color="primary" 
              class="my-3"
              @click="$router.push({ name: 'Voluntarios' })"
            >Voluntarios</v-btn>

            <v-btn
              @click="$router.push('/principal')"
              block
              outlined
              color="warning"
              class="my-5"
              >VOLVER</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="8">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "admminitracion",

  data() {
    return {};
  },
};
</script>
